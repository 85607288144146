import React from 'react';
import Button from '../components/Button/Button';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import Astra from '../components/Images/Logo';
import SRE from '../components/Images/Sre';
import EnamLima from '../components/Images/EnamLima'
import SatuIndonesia from '../components/Images/SatuIndonesia';
import { Link } from 'gatsby';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import timelineImage from '../img/Timelinev2.png';

const Index = () => (
  <Layout withRegister>
    <SEO title="Home" />
    <section className="">
      <div
        className="py-6 md:py-10"
        style={{
          backgroundImage: `url("https://i.ibb.co/zPvHKnQ/Landing.png")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="container mx-auto px-6 lg:flex">
          <div className="text-center lg:text-left w-full">
            <div className="flex justify-between items-center">
              <div className="w-32 lg:w-40 mb-12 lg:mb-24">
                <Astra />
              </div>
              <div className="w-16 lg:w-24 mb-12 lg:mb-24">
                <EnamLima />
              </div>
              <div className="w-24 lg:w-32 mb-12 lg:mb-24">
                <SatuIndonesia />
              </div>
            </div>
            <h1 className="mt-6 text-3xl lg:text-4xl lg:mt-10 xl:mt-10 xl:text-5xl font-bold leading-normal text-primary">
            Pengumuman Finalis <br/>Digital Innovation & Technology Competition
            </h1>
            {/* <p className="text-2xl lg:text-3xl mt-2 font-roman text-secondary">Selamat kepada 10 orang finalis untuk masing - masing stream lomba dalam tiap kategori.</p>
            <p className="justify-center mt-6 md:mt-6 flex gap-x-4 lg:justify-start py-10 mb-10 md:py-12 md:mb-12">
              <a href="https://drive.google.com/file/d/1m0BrUTSqGKVNPAPKXQA6xv_mnEcwSqf-/view?usp=sharing" target="_blank" rel="norefferer">
                <Button>Future of Mobility</Button>
              </a>
              <a href="https://drive.google.com/file/d/1m0BrUTSqGKVNPAPKXQA6xv_mnEcwSqf-/view?usp=sharing" target="_blank" rel="norefferer">
                <Button className="text-blue-700" color="bg-white hover:bg-gray-200">Future of Energy</Button>
              </a>
            </p> */}
              <div className='flex justify-center md:justify-end items-end mt-24'>
              <p className='text-white mr-2'>in collaboration</p>
            <div className="w-16 lg:w-24">
                <SRE />
              </div>
              </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container mx-auto text-center flex items-center flex-col">
        <div className="m-8 md:m-12 text-center md:text-left">
          <p className="text-base md:text-lg text-center">
          Selamat kepada 10 orang finalis untuk masing - masing stream lomba dalam tiap kategori! Berikut adalah daftar finalis :</p>
          {/* <p className='text-primary'><b>#KitaSATUIndonesia</b></p>

          <p className="mt-8 md:mt-12 text-base md:text-lg text-center">
            <b>InnovNation Competition</b> merupakan wadah bagi mahasiswa untuk berkompetisi dalam
            berbagi ide dan inovasi melalui solusi sederhana, relevan, dan praktis untuk menjawab
            tantangan bisnis & keberlanjutan. Ini terinspirasi oleh bagaimana sebagai siswa mereka
            mungkin mengalami dan memahami kesulitan yang muncul selama pandemi dan pada saat yang
            sama memikirkan peluang yang dapat membantu memecahkan tantangan bisnis &
            keberlanjutan.​
          </p> */}
        </div>
      </div>
    </section>

    {/* Start Section Timeline */}
    <section id="timeline" className="">
      {/* <h1 className="text-2xl md:text-4xl font-bold mb-5 mt-4 text-center">Timeline</h1> */}
      {/* <div className="flex justify-center m-8 md:m-12">
        <img src={timelineImage} alt="" />
      </div> */}
    </section>
    {/* End of Section Timeline */}

    {/* <section id="prize" className="md:py-10">
      <div
        className="py-8 md:py-12 text-center"
        style={{
          background: `rgb(0,86,158)`,
          background: `linear-gradient(270deg, rgba(0,86,158,0.6) 0%, rgba(0,86,158,0.8) 10%, rgba(0,86,158,1) 50%, rgba(0,86,158,0.8) 90%, rgba(0,86,158,0.6) 100%)`,
        }}
      >
        <h1 className="text-2xl md:text-4xl font-bold text-white">TOTAL HADIAH 190 JUTA RUPIAH</h1>
      </div>
    </section> */}

    {/* <section id="theme" className='pb-20'>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <p className="text-2xl lg:text-3xl mt-6 font-bold text-primary mb-4 text-center">Universitas</p>
          <div className="grid grid-cols-2 gap-y-2 gap-x-12">
            <p className="text-right">Juara I</p>
            <p className="">Rp 25.000.000,-</p>
            <p className="text-right">Juara II</p>
            <p className="">Rp 15.000.000,-</p>
            <p className="text-right">Juara III</p>
            <p className="">Rp 10.000.000,-</p>
            <p className="text-right">Juara Harapan I</p>
            <p className="">Rp 5.000.000,-</p>
            <p className="text-right">Juara Harapan II</p>
            <p className="">Rp 5.000.000,-</p>
            <p className="text-right">10 Besar</p>
            <p className="">E-certificates</p>
          </div>
        </div>
        <div>
          <p className="text-2xl lg:text-3xl mt-6 font-bold text-primary mb-4 text-center">SMA/SMK</p>
          <div className="grid grid-cols-2 gap-y-2 gap-x-12">
            <p className="text-right">Juara I</p>
            <p className="">Rp 15.000.000,-</p>
            <p className="text-right">Juara II</p>
            <p className="">Rp 10.000.000,-</p>
            <p className="text-right">Juara III</p>
            <p className="">Rp 5.000.000,-</p>
            <p className="text-right">Juara Harapan I</p>
            <p className="">Rp 2.500.000,-</p>
            <p className="text-right">Juara Harapan II</p>
            <p className="">Rp 2.500.000,-</p>
            <p className="text-right">10 Besar</p>
            <p className="">E-certificates</p>
          </div>
        </div>

      </div>
    </section> */}

    <section id="finalis" className="">
    <div className="container mx-auto text-center flex items-center flex-col">
    <h1 className="text-xl lg:text-2xl lg:mt-10 xl:mt-10 xl:text-3xl leading-none font-bold text-primary">
      Future of Energy</h1>
          </div>
      <div className="container mx-auto text-left flex items-start flex-col">
        <div className="mx-8 md:mx-12">
          <ul className="list-outside list-none md:text-lg mt-8 ml-6 text-justify">
          <li><b>Universitas:</b><br/>
            <ol className="list-outside list-decimal md:text-lg my-2 ml-12">
              <li>
              <p className='text-primary'><b>Sukichain</b></p>Inovasi Charging Station Pintar Berbasis Teknologi PMFC-DMFC-Fotokatalis untuk Konversi Rumput Teki dan CO2 Menjadi Energi Listrik Terbarukan
              </li>
              <li>
              <p className='text-primary'><b>Benawa Prada</b></p>Gyro Solar Cell IoT Boat (GSI BOAT) Perahu IoT Berbasis Panel Surya dengan Gyro Stabilizer Untuk Meningkatkan Tangkapan Ikan Nelayan
              </li>
              <li>
              <p className='text-primary'><b>The Fisherman</b></p>Pemanfaatan Efek Peltier dalam Inovasi TEC-BOX Bertenaga Surya sebagai Pendingin Ikan Ramah Lingkungan bagi Nelayan
              </li>
              <li>
              <p className='text-primary'><b>Soedirman</b></p>PLTMH Turbin Tipe Francis Dilengkapi Conical Draft Tube D/L 4 Untuk Mendukung Implementasi Modernisasi Irigasi Dan Kebutuhan Listrik Sekitar Bendung
              </li>
              <li>
              <p className='text-primary'><b>Chens Team</b></p>Pengembangan Dye Sensitizer Solar Cell (Dssc) Berbahan Ekstrak Lumut Kerak (Lichenes) Untuk Mendukung Transformasi Energi
              </li>
              <li>
              <p className='text-primary'><b>BIO HOMY Team</b></p>Bio Homy: Inovasi Biogas Portabel dengan Memanfaatkan Sampah Organik Rumah Tangga Guna Mendukung Penurunan Emisi Gas Rumah Kaca dan Kemandirian Energi 2035
              </li>
              <li>
              <p className='text-primary'><b>SELFIErce</b></p>SELFIE (Salinity Gradient Energy)- An Advanced Technology Converting Sea Water and River into Electricity
              </li>
              <li>
              <p className='text-primary'><b>Greenesa Team</b></p>Konsep Green Roof sebagai Green Infrastructure untuk permukiman Berkelanjutan di Indonesia
              </li>
              <li>
              <p className='text-primary'><b>Ikuzo Team</b></p>Gravitation Water Vortex Power Plant Dengan Metode Penyimpanan Under Water Storage Energy Untuk Stasiun Pengisian Kendaraan Listrik Umum
              </li>
              <li>
              <p className='text-primary'><b>Carbon Justice Warrior</b></p>Smart Cattle Biodigester sebagai Upaya Mewujudkan Energi Hijau Masa Depan dan Ekosistem Carbon Offset Terintegrasi berbasis Internet of Things
              </li>
            </ol>
            </li>
            <li>
            <b>SMA/SMK/Sederajat:</b><br/>
            <ol className="list-outside list-decimal md:text-lg my-2 ml-12">
              <li>
              <p className='text-primary'><b>Tim Asteria</b></p>Pembangkit Listrik Cuaca (PELICA) Berbasis Mikrokontroler Arduino Mega 2560 Dilengkapi Dengan Sensor Suhu, Air Dan Kecepatan Angin.
              </li>
              <li>
              <p className='text-primary'><b>Brave Team</b></p>SIS-TECH: Subak Irrigation System by Turbulent Technology
              </li>
              <li>
              <p className='text-primary'><b>Scherrer Team</b></p>Electric Box Roof: Improvisasi Teknologi Atap Konduktif Berbasis Thermoelectric Generator Sebagai Pembangkit Listrik Tenaga Radiasi Matahari Pada Atap Rumah
              </li>
              <li>
              <p className='text-primary'><b>TIM B MAN 1 PEKANBARU</b></p>Teknologi Pembangkit Listrik Sistem Hybrid Tenaga Bayu Dan Surya (Basur) Sebagai Sumber Daya Untuk Lampu Penerangan Jalan Di Kota Pekanbaru
              </li>
              <li>
              <p className='text-primary'><b>TIM RIZDA</b></p>"BINTANG SINERGI" Bio-Electric Satu Bejana Berbahan Limbah Tahu dan Rokok Sebagai Deversifikasi Energi Baru Terbarukan
              </li>
              <li>
              <p className='text-primary'><b>Eco-Team</b></p>Eco-enzym maker : Pengonversi Sampah Organik Menjadi Pengurang Polutan dan Energi Listrik Terbarukan
              </li>
              <li>
              <p className='text-primary'><b>GREEN POWER BANK</b></p>Thermal Generator Pada Saluran Gas Buang Digunakan Sebagai Charging Dan Lighting System Pada Kendaraan
              </li>
              <li>
              <p className='text-primary'><b>DELONIEL</b></p>MOCC (Mobile Oxygen Concentrator of City)
              </li>
              <li>
              <p className='text-primary'><b>Meryanti</b></p>Kompor Listrik Panel Surya Sebagai Jawaban Untuk Mengurangi Polusi Udara
              </li>
              <li>
              <p className='text-primary'><b>BARASIK</b></p>Optimasi Produksi Biodiesel dari Limbah Oli bekas dan Styrofoam dengan Katalis Cangkang Kerang
              </li>
            </ol>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section id="theme" className="">
    <div className="container mx-auto text-center flex items-center flex-col">
    <h1 className="mt-10 text-xl lg:text-2xl lg:mt-12 xl:mt-10 xl:text-3xl leading-none font-bold text-primary">
          Future of Mobility</h1>
          </div>
      <div className="container mx-auto text-left flex items-start flex-col">
        <div className="mx-8 md:mx-12">
        <ul className="list-outside list-none md:text-lg mt-8 ml-6 text-justify">
          <li><b>Universitas:</b><br/>
            <ol className="list-outside list-decimal md:text-lg my-2 ml-12">
              <li>
              <p className='text-primary'><b>Aerio</b></p>Wireless Charger Sebagai Alat Pengisi Daya Pada Mobil Listrik Masa Depan
              </li>
              <li>
              <p className='text-primary'><b>Andromeda</b></p>eRoads: Electrical Road System with Hybrid Energy Wind Turbine And Solar Power
              </li>
              <li>
              <p className='text-primary'><b>Cakrawala Team 1</b></p>Perancangan Transportasi Umum sebagai Upaya Adaptasi Perubahan Gaya Hidup Masyarakat
              </li>
              <li>
              <p className='text-primary'><b>Dosbog</b></p>SSB Sebagai Infrastruktur Pendukung Mobilitas Masa Depan Daerah Pariwisata
              </li>
              <li>
              <p className='text-primary'><b>Guardian Team</b></p>GDrone Health is Our Priority
              </li>
              <li>
              <p className='text-primary'><b>Hiyon Pick Up</b></p>Teknologi Fuel Cell Electric Vehicle (FCEV) Kapasitas 600 cc Untuk Transportasi Hasil Pertanian Perdesaan
              </li>
              <li>
              <p className='text-primary'><b>NGEPIT</b></p>NGEPIT (Smart Bike Berbasis Internet of Things)
              </li>
              <li>
              <p className='text-primary'><b>Serut</b></p>Sistem Transportasi Swatantra Untuk Mendukung Keberlanjutan Sektor Transportasi di Era Society 5.0
              </li>
              <li>
              <p className='text-primary'><b>Solar</b></p>AUTRAM
              </li>
              <li>
              <p className='text-primary'><b>SYARIF HIDAYATULLAH</b></p>Sinergi 3 Solusi Gesit: Green Energy, Socio-Culture & Information Technology
              </li>
            </ol>
            </li>
            <li>
            <b>SMA/SMK/Sederajat:</b><br/>
            <ol className="list-outside list-decimal md:text-lg my-2 ml-12">
              <li>
              <p className='text-primary'><b>BERMATA PATRA</b></p>Wireless Charger Sebagai Alat Pengisi Daya Pada Mobil Listrik Masa Depan
              </li>
              <li>
              <p className='text-primary'><b>CAKLIS LAMONGAN</b></p>Caklis
              </li>
              <li>
              <p className='text-primary'><b>DSR Team</b></p>DSR
              </li>
              <li>
              <p className='text-primary'><b>G-Thac G-One</b></p>Smart Cabin
              </li>
              <li>
              <p className='text-primary'><b>Jotaro</b></p>ONE-GO
              </li>
              <li>
              <p className='text-primary'><b>Pemuda Inovatif</b></p>BERUDU, Kendaraan Listrik Roda Tiga Untuk Perkantoran
              </li>
              <li>
              <p className='text-primary'><b>SACIBA</b></p>Monitoring Driver Health
              </li>
              <li>
              <p className='text-primary'><b>TEI SACIBA</b></p>Smart Wheelchair for Health Monitoring Future Of Mobility
              </li>
              <li>
              <p className='text-primary'><b>Tim Socrates M2KM</b></p>ISAFE Car
              </li>
              <li>
              <p className='text-primary'><b>Youth Of HERO</b></p>VELCE_Aplikasi All-Service Kendaraan Listrik
              </li>
            </ol>
            </li>
          </ul>
        </div>
      </div>
      <div className="container mx-auto text-center flex items-center flex-col">
        <div className="m-8 md:m-12 text-center md:text-left">
          <p className="text-2xl md:text-xl text-center font-bold">
          Selamat melanjutkan ke tahap berikutnya!    </p>     
          </div>
          </div>
    </section>
  </Layout>
);

export default Index;
